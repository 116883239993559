export const environment = {
    production: true,
    BASE_URL: "https://stage.webapi.digitalpassportsystem.hitachienergy.com:443/",
    appInsights: "b460d9d3-4421-4949-951e-905439470d27",
    clientID: "c51cce15-d859-4c67-9846-3d1595bbcffd",
    scopes: ["User.ReadBasic.All", "a5566358-f950-4500-8529-e2ae7a133b17/Access_dps_api", "User.Read"],
    redirectURi: "https://stage.digitalpassportsystem.hitachienergy.com",
    subscription: "48ff6b3f148f4f7e9a6b5d12a3cc0d69",
    authority: "https://login.microsoftonline.com/7831e6d9-dc6c-4cd1-9ec6-1dc2b4133195",
    blobContainer: "pglz01-ne-stblob-dps-302",
    azureSearchKey: "Hiest018FlxeG6DitUH4IdVlTc8VxFU60KiiBEu4FXAzSeDnyAC4",
    azureSearchURL: "https://stage.search.digitalpassportsystem.hitachienergy.com",
    bingCredential: "AhHGm49KKwySazCHIgNbmzE0l9hNR35wKyMGwJbhrKUmg_AIpLyjX-uqiBMGUdIf",
    azureMapKey: "XlfDX5Cd0QoxsU1EYiFqghl5WP48zKz29CIB60jNcdk",
    title: "-  Stage",
    blobContainerCustomReport: "pglz01-ne-stblob-dps-303",
    downloadZip: "https://stage.export.digitalpassportsystem.hitachienergy.com/api/zip?code=4zDb8iVy8azs8YySgOjLzRs5HJBqmo379JeJhFNdXqSZAzFuNlS9VQ==",
    CCRPUrl: "https://stage.ccrp.hitachienergy.com/en/complaint/",
    clientSecret: "test",
    isCCRP: "yes",
    CCRPAppId: "9AAG029588",
    ServIsAppId: "9AAG029556",
    organizationName: "HE",
    signlarUrl: "https://stage.download.digitalpassportsystem.hitachienergy.com/api/negotiate?code=Ywg5Z6sO5JrrM3McxQSHmVvnxd59CX-fjcJjn6WFEfC-AzFuliF8Og==",
    productHierarchy: "https://stage.export.digitalpassportsystem.hitachienergy.com/api/ProductHierarchy?code=Nn_MXgMeXaFdWU77Qki-Fbq17eDU8VgRFtic9WhnLRqGAzFug5AEdg==",
    generateReportProductionTimeCaptured: "https://stage.export.digitalpassportsystem.hitachienergy.com/api/GenerateReportForProductionTimeCaptureDetails?code=_WyhJERoBu-gkZPvmbEUJSwUq5PLHGLqPlmDS3g-INKfAzFuaEzcKA==",
    environment: "HITACHI",
    downloadQCZip: "https://stage.export.digitalpassportsystem.hitachienergy.com/api/ProductMultipleDocumentsDownload?code=knajTmrw4WdClhXELbVo6oaFCKaXcpf7QwtBrufXrBU7AzFuv3lN5g==",
    ACTAS_BASE_URI: "https://hpg-webapp-000255.azurewebsites.net"
};
